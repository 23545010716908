import HttpRequest from './core/http_request';

class AdminProvider extends HttpRequest {
  createAdmin (data) {
    return this.create('', data);
  }

  getMember (data) {
    return this.request('get', '/member', data);
  }

  updateAdmin (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/member/updateAdmin/' + id, data);
  }

  getpaymentAndMember (data) {
    return this.request('get', '/payment/paymentAndMember/', data);
  }

  addAccToMeta (data) {
    return this.request('post', '/tradeAccount/addAccount/', data);
  }

  getTradeByID (data) {
    return this.request('get', '/tradeAccount/addAccount/', data);
  }

  updateTradeAccount (data) {
    return this.request('post', '/tradeAccount/ByID/', data);
  }

  suspended (data) {
    return this.request('post', '/tradeAccount/updateStatus/', data);
  }

  addPrefixRule (data) {
    delete data._id;
    return this.request('post', '/prefixRule', data);
  }

  getPrefixRule (data) {
    return this.request('get', '/prefixRule', data);
  }

  getPrefixRuleByID (data) {
    const id = data._id;
    return this.request('get', '/prefixRule/id/' + id, data);
  }

  updatePrefixRule (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/prefixRule/id/' + id, data);
  }

  removePrefixRule (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/prefixRule/remove/' + id, data);
  }

  prefixRuleUpdatePosition (data) {
    return this.request('post', '/prefixRule/updatePosition', data);
  }

  uploadSlipWithdraw (data) {
    return this.request('upload', '/uploadFile/uploadSlipWithdraw', data);
  }

  getSymbolList (data) {
    return this.request('get', '/symbol', data);
  }

  addSymbol (data) {
    delete data._id;
    return this.request('post', '/symbol', data);
  }

  updateSymbol (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/symbol/id/' + id, data);
  }

  removeSymbol (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/symbol/remove/' + id, data);
  }

  getSymbolByID (data) {
    const id = data._id;
    return this.request('get', '/symbol/id/' + id, data);
  }

  getNotifyListDemo (data) {
    return this.request('get', '/tradeAccount/notifyDemo', data);
  }

  getNotifyListReal (data) {
    return this.request('get', '/tradeAccount/notifyReal', data);
  }

  undeploy (data) {
    return this.request('post', '/tradeAccount/updeploy', data);
  }

  getAccountMetaState (data) {
    return this.request('post', '/tradeAccount/accountMetastate', data);
  }

  getWithdraw () {
    return this.request('get', '/withdraw');
  }

  updateWithdraw (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/withdraw/updateStatus/' + id, data);
  }

  getWithdrawByID (id) {
    return this.request('get', '/withdraw/id/' + id);
  }

  getRiskPortlist () {
    return this.request('get', '/tradeAccount/getRiskPortlist');
  }
}

export default AdminProvider;
