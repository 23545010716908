import { memberService, adminService } from '../services'; //, adminService, masterService

const apiModule = {
  namespaced: true,
  state: {
    memberService: memberService,
    adminService: adminService
    // masterService: masterService
  },
  actions: {
    async apiCall ({ commit }, data) {
      const result = await this.state.api[data.svName][data.fnName](data.param);
      return result;
    }
  }
};

export default apiModule;
