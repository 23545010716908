import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/example/index.vue';
import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
// Example -----------------------
  // dashboard
  { path: '/example/', name: 'example-Home', component: Home },

  {
    path: '/example/index2',
    name: 'example-index2',
    component: () => import(/* webpackChunkName: "index2" */ '../views/example/index2.vue'),
  },

  // components
  {
    path: '/example/components/tabs',
    name: 'example-tabs',
    component: () => import(/* webpackChunkName: "components-tabs" */ '../views/example/components/tabs.vue'),
  },
  {
    path: '/example/components/accordions',
    name: 'example-accordions',
    component: () => import(/* webpackChunkName: "components-accordions" */ '../views/example/components/accordions.vue'),
  },
  {
    path: '/example/components/modals',
    name: 'example-modals',
    component: () => import(/* webpackChunkName: "components-modals" */ '../views/example/components/modals.vue'),
  },
  {
    path: '/example/components/cards',
    name: 'example-cards',
    component: () => import(/* webpackChunkName: "components-cards" */ '../views/example/components/cards.vue'),
  },
  {
    path: '/example/components/carousel',
    name: 'example-carousel',
    component: () => import(/* webpackChunkName: "components-carousel" */ '../views/example/components/carousel.vue'),
  },

  {
    path: '/example/components/timeline',
    name: 'example-timeline',
    component: () => import(/* webpackChunkName: "components-timeline" */ '../views/example/components/timeline.vue'),
  },
  {
    path: '/example/components/media-object',
    name: 'example-media-object',
    component: () => import(/* webpackChunkName: "components-media-object" */ '../views/example/components/media_object.vue'),
  },
  {
    path: '/example/components/list-group',
    name: 'example-list-group',
    component: () => import(/* webpackChunkName: "components-list-group" */ '../views/example/components/list_group.vue'),
  },
  {
    path: '/example/components/pricing-table',
    name: 'example-pricing-table',
    component: () => import(/* webpackChunkName: "components-pricing-table" */ '../views/example/components/pricing_table.vue'),
  },
  {
    path: '/example/components/notifications',
    name: 'example-notifications',
    component: () => import(/* webpackChunkName: "components-notifications" */ '../views/example/components/toast.vue'),
  },

  {
    path: '/example/components/lightbox',
    name: 'example-lightbox',
    component: () => import(/* webpackChunkName: "components-lightbox" */ '../views/example/components/lightbox.vue'),
  },
  {
    path: '/example/components/countdown',
    name: 'example-countdown',
    component: () => import(/* webpackChunkName: "components-countdown" */ '../views/example/components/countdown.vue'),
  },
  {
    path: '/example/components/counter',
    name: 'example-counter',
    component: () => import(/* webpackChunkName: "components-counter" */ '../views/example/components/counter.vue'),
  },
  {
    path: '/example/components/sweetalert',
    name: 'example-sweetalert',
    component: () => import(/* webpackChunkName: "components-sweetalert" */ '../views/example/components/sweetalert.vue'),
  },

  // fonts
  {
    path: '/example/font-icons',
    name: 'example-font-icons',
    component: () => import(/* webpackChunkName: "font-icons" */ '../views/example/font_icons.vue'),
  },

  // pages
  {
    path: '/example/pages/helpdesk',
    name: 'example-helpdesk',
    component: () => import(/* webpackChunkName: "pages-helpdesk" */ '../views/example/pages/helpdesk.vue'),
  },
  {
    path: '/example/pages/contact-us',
    name: 'example-contact-us',
    component: () => import(/* webpackChunkName: "pages-contact-us" */ '../views/example/pages/contact_us.vue'),
  },
  {
    path: '/example/pages/faq',
    name: 'example-faq',
    component: () => import(/* webpackChunkName: "pages-faq" */ '../views/example/pages/faq.vue'),
  },
  {
    path: '/example/pages/faq2',
    name: 'example-faq2',
    component: () => import(/* webpackChunkName: "pages-faq2" */ '../views/example/pages/faq2.vue'),
  },
  {
    path: '/example/pages/privacy-policy',
    name: 'example-privacy-policy',
    component: () => import(/* webpackChunkName: "pages-privacy-policy" */ '../views/example/pages/privacy_policy.vue'),
  },
  {
    path: '/example/pages/coming-soon',
    name: 'example-coming-soon',
    component: () => import(/* webpackChunkName: "pages-coming-soon" */ '../views/example/pages/coming_soon.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/pages/error404',
    name: 'example-error404',
    component: () => import(/* webpackChunkName: "pages-error404" */ '../views/example/pages/error404.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/pages/error500',
    name: 'example-error500',
    component: () => import(/* webpackChunkName: "pages-error500" */ '../views/example/pages/error500.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/pages/error503',
    name: 'example-error503',
    component: () => import(/* webpackChunkName: "pages-error503" */ '../views/example/pages/error503.vue'),
    meta: { layout: 'auth' }
  },
  {
    path: '/example/pages/maintenence',
    name: 'example-maintenence',
    component: () => import(/* webpackChunkName: "pages-maintenence" */ '../views/example/pages/maintenence.vue'),
    meta: { layout: 'auth' }
  },
  {
    path: '/example/pages/blank-page',
    name: 'example-blank-page',
    component: () => import(/* webpackChunkName: "pages-blank-page" */ '../views/example/pages/blank_page.vue'),
  },
  {
    path: '/example/pages/sample',
    name: 'example-sample',
    component: () => import(/* webpackChunkName: "pages-sample" */ '../views/example/pages/sample.vue'),
  },

  // auth
  {
    path: '/example/auth/login-boxed',
    name: 'example-login-boxed',
    component: () => import(/* webpackChunkName: "auth-login-boxed" */ '../views/example/auth/login_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/register-boxed',
    name: 'example-register-boxed',
    component: () => import(/* webpackChunkName: "auth-register-boxed" */ '../views/example/auth/register_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/lockscreen-boxed',
    name: 'example-lockscreen-boxed',
    component: () => import(/* webpackChunkName: "auth-lockscreen-boxed" */ '../views/example/auth/lockscreen_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/pass-recovery-boxed',
    name: 'example-pass-recovery-boxed',
    component: () => import(/* webpackChunkName: "auth-pass-recovery-boxed" */ '../views/example/auth/pass_recovery_boxed.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/login',
    name: 'example-login',
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/example/auth/login.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/register',
    name: 'example-register',
    component: () => import(/* webpackChunkName: "auth-register" */ '../views/example/auth/register.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/lockscreen',
    name: 'example-lockscreen',
    component: () => import(/* webpackChunkName: "auth-lockscreen" */ '../views/example/auth/lockscreen.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/example/auth/pass-recovery',
    name: 'example-pass-recovery',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/example/auth/pass_recovery.vue'),
    meta: { layout: 'auth' },
  },

  // elements
  {
    path: '/example/elements/alerts',
    name: 'example-alerts',
    component: () => import(/* webpackChunkName: "elements-alerts" */ '../views/example/elements/alerts.vue'),
  },
  {
    path: '/example/elements/avatar',
    name: 'example-avatar',
    component: () => import(/* webpackChunkName: "elements-avatar" */ '../views/example/elements/avatar.vue'),
  },
  {
    path: '/example/elements/badges',
    name: 'example-badges',
    component: () => import(/* webpackChunkName: "elements-badges" */ '../views/example/elements/badges.vue'),
  },
  {
    path: '/example/elements/breadcrumbs',
    name: 'example-breadcrumbs',
    component: () => import(/* webpackChunkName: "elements-breadcrumbs" */ '../views/example/elements/breadcrumbs.vue'),
  },
  {
    path: '/example/elements/buttons',
    name: 'example-buttons',
    component: () => import(/* webpackChunkName: "elements-buttons" */ '../views/example/elements/buttons.vue'),
  },
  {
    path: '/example/elements/buttons-group',
    name: 'example-buttons-group',
    component: () => import(/* webpackChunkName: "elements-buttons-group" */ '../views/example/elements/buttons_group.vue'),
  },
  {
    path: '/example/elements/color-library',
    name: 'example-color-library',
    component: () => import(/* webpackChunkName: "elements-color-library" */ '../views/example/elements/color_library.vue'),
  },
  {
    path: '/example/elements/dropdown',
    name: 'example-dropdown',
    component: () => import(/* webpackChunkName: "elements-dropdown" */ '../views/example/elements/dropdown.vue'),
  },
  {
    path: '/example/elements/infobox',
    name: 'example-infobox',
    component: () => import(/* webpackChunkName: "elements-infobox" */ '../views/example/elements/infobox.vue'),
  },
  {
    path: '/example/elements/jumbotron',
    name: 'example-jumbotron',
    component: () => import(/* webpackChunkName: "elements-jumbotron" */ '../views/example/elements/jumbotron.vue'),
  },
  {
    path: '/example/elements/loader',
    name: 'example-loader',
    component: () => import(/* webpackChunkName: "elements-loader" */ '../views/example/elements/loader.vue'),
  },
  {
    path: '/example/elements/pagination',
    name: 'example-pagination',
    component: () => import(/* webpackChunkName: "elements-pagination" */ '../views/example/elements/pagination.vue'),
  },
  {
    path: '/example/elements/popovers',
    name: 'example-popovers',
    component: () => import(/* webpackChunkName: "elements-popovers" */ '../views/example/elements/popovers.vue'),
  },
  {
    path: '/example/elements/progress-bar',
    name: 'example-progress-bar',
    component: () => import(/* webpackChunkName: "elements-progress-bar" */ '../views/example/elements/progress_bar.vue'),
  },
  {
    path: '/example/elements/search',
    name: 'example-search',
    component: () => import(/* webpackChunkName: "elements-search" */ '../views/example/elements/search.vue'),
  },
  {
    path: '/example/elements/tooltips',
    name: 'example-tooltips',
    component: () => import(/* webpackChunkName: "elements-tooltips" */ '../views/example/elements/tooltips.vue'),
  },
  {
    path: '/example/elements/treeview',
    name: 'example-treeview',
    component: () => import(/* webpackChunkName: "elements-treeview" */ '../views/example/elements/treeview.vue'),
  },
  {
    path: '/example/elements/typography',
    name: 'example-typography',
    component: () => import(/* webpackChunkName: "elements-typography" */ '../views/example/elements/typography.vue'),
  },

  // tables
  {
    path: '/example/tables',
    name: 'example-tables',
    component: () => import(/* webpackChunkName: "tables" */ '../views/example/tables.vue'),
  },

  // users
  {
    path: '/example/users/profile',
    name: 'example-profile',
    component: () => import(/* webpackChunkName: "users-profile" */ '../views/example/users/profile.vue'),
  },
  {
    path: '/example/users/account-setting',
    name: 'example-account-setting',
    component: () => import(/* webpackChunkName: "users-account-setting" */ '../views/example/users/account_setting.vue'),
  },

  // drag&drop
  {
    path: '/example/dragndrop',
    name: 'example-dragndrop',
    component: () => import(/* webpackChunkName: "dragndrop" */ '../views/example/dragndrop.vue'),
  },

  // charts
  {
    path: '/example/charts/apex-chart',
    name: 'example-apex-chart',
    component: () => import(/* webpackChunkName: "charts-apex-chart" */ '../views/example/charts/apex_chart.vue'),
  },

  // widgets
  {
    path: '/example/widgets',
    name: 'example-widgets',
    component: () => import(/* webpackChunkName: "widgets" */ '../views/example/widgets.vue'),
  },

  // forms
  {
    path: '/example/forms/basic',
    name: 'example-basic',
    component: () => import(/* webpackChunkName: "forms-basic" */ '../views/example/forms/basic.vue'),
  },
  {
    path: '/example/forms/input-group',
    name: 'example-input-group',
    component: () => import(/* webpackChunkName: "forms-input-group" */ '../views/example/forms/input_group.vue'),
  },
  {
    path: '/example/forms/layouts',
    name: 'example-layouts',
    component: () => import(/* webpackChunkName: "forms-layouts" */ '../views/example/forms/layouts.vue'),
  },
  {
    path: '/example/forms/validation',
    name: 'example-validation',
    component: () => import(/* webpackChunkName: "forms-validation" */ '../views/example/forms/validation.vue'),
  },
  {
    path: '/example/forms/checkbox-radio',
    name: 'example-checkbox-radio',
    component: () => import(/* webpackChunkName: "forms-checkbox-radio" */ '../views/example/forms/checkbox_radio.vue'),
  },
  {
    path: '/example/forms/switches',
    name: 'example-switches',
    component: () => import(/* webpackChunkName: "forms-switches" */ '../views/example/forms/switches.vue'),
  },
  {
    path: '/example/forms/wizards',
    name: 'example-wizards',
    component: () => import(/* webpackChunkName: "forms-wizards" */ '../views/example/forms/wizards.vue'),
  },
  {
    path: '/example/forms/file-upload',
    name: 'example-file-upload',
    component: () => import(/* webpackChunkName: "forms-file-upload" */ '../views/example/forms/fileupload.vue'),
  },
  {
    path: '/example/forms/clipboard',
    name: 'example-clipboard',
    component: () => import(/* webpackChunkName: "forms-clipboard" */ '../views/example/forms/clipboard.vue'),
  },
  {
    path: '/example/forms/date-picker',
    name: 'example-date-picker',
    component: () => import(/* webpackChunkName: "forms-date-picker" */ '../views/example/forms/date_range_picker.vue'),
  },
  {
    path: '/example/forms/input-mask',
    name: 'example-input-mask',
    component: () => import(/* webpackChunkName: "forms-input-mask" */ '../views/example/forms/input_mask.vue'),
  },
  {
    path: '/example/forms/quill-editor',
    name: 'example-quill-editor',
    component: () => import(/* webpackChunkName: "forms-quill-editor" */ '../views/example/forms/quill_editor.vue'),
  },
  {
    path: '/example/forms/touchspin',
    name: 'example-touchspin',
    component: () => import(/* webpackChunkName: "forms-touchspin" */ '../views/example/forms/touchspin.vue'),
  },
  {
    path: '/example/forms/markdown-editor',
    name: 'example-markdown-editor',
    component: () => import(/* webpackChunkName: "forms-markdown-editor" */ '../views/example/forms/markdown_editor.vue'),
  },
  {
    path: '/example/forms/select2',
    name: 'example-select2',
    component: () => import(/* webpackChunkName: "forms-select2" */ '../views/example/forms/select2.vue'),
  },

  // apps
  {
    path: '/example/apps/chat',
    name: 'example-chat',
    component: () => import(/* webpackChunkName: "apps-chat" */ '../views/example/apps/chat.vue'),
  },
  {
    path: '/example/apps/mailbox',
    name: 'example-mailbox',
    component: () => import(/* webpackChunkName: "apps-mailbox" */ '../views/example/apps/mailbox.vue'),
  },
  {
    path: '/example/apps/todo-list',
    name: 'example-todo-list',
    component: () => import(/* webpackChunkName: "apps-todo-list" */ '../views/example/apps/todo_list.vue'),
  },
  {
    path: '/example/apps/contacts',
    name: 'example-contacts',
    component: () => import(/* webpackChunkName: "apps-contacts" */ '../views/example/apps/contacts.vue'),
  },
  {
    path: '/example/apps/notes',
    name: 'example-notes',
    component: () => import(/* webpackChunkName: "apps-notes" */ '../views/example/apps/notes.vue'),
  },
  {
    path: '/example/apps/scrumboard',
    name: 'example-scrumboard',
    component: () => import(/* webpackChunkName: "apps-scrumboard" */ '../views/example/apps/scrumboard.vue'),
  },
  {
    path: '/example/apps/calendar',
    name: 'example-calendar',
    component: () => import(/* webpackChunkName: "apps-calendar" */ '../views/example/apps/calendar.vue'),
  },
  {
    path: '/example/apps/invoice/list',
    name: 'example-invoice-list',
    component: () => import(/* webpackChunkName: "apps-invoice-list" */ '../views/example/apps/invoice/list.vue'),
  },
  {
    path: '/example/apps/invoice/preview',
    name: 'example-invoice-preview',
    component: () => import(/* webpackChunkName: "apps-invoice-preview" */ '../views/example/apps/invoice/preview.vue'),
  },
  {
    path: '/example/apps/invoice/add',
    name: 'example-invoice-add',
    component: () => import(/* webpackChunkName: "apps-invoice-add" */ '../views/example/apps/invoice/add.vue'),
  },
  {
    path: '/example/apps/invoice/edit',
    name: 'example-invoice-edit',
    component: () => import(/* webpackChunkName: "apps-invoice-edit" */ '../views/example/apps/invoice/edit.vue'),
  },

  // tables
  {
    path: '/example/tables/basic',
    name: 'example-table-basic',
    component: () => import(/* webpackChunkName: "tables-basic" */ '../views/example/tables/basic.vue'),
  },
  {
    path: '/example/tables/striped',
    name: 'example-striped',
    component: () => import(/* webpackChunkName: "tables-striped" */ '../views/example/tables/striped.vue'),
  },
  {
    path: '/example/tables/order-sorting',
    name: 'example-order-sorting',
    component: () => import(/* webpackChunkName: "tables-order-sorting" */ '../views/example/tables/order_sorting.vue'),
  },
  {
    path: '/example/tables/multi-column',
    name: 'example-multi-column',
    component: () => import(/* webpackChunkName: "tables-multi-column" */ '../views/example/tables/multi_column.vue'),
  },
  {
    path: '/example/tables/multiple-tables',
    name: 'example-multiple-tables',
    component: () => import(/* webpackChunkName: "tables-multiple-tables" */ '../views/example/tables/multiple_tables.vue'),
  },
  {
    path: '/example/tables/alt-pagination',
    name: 'example-alt-pagination',
    component: () => import(/* webpackChunkName: "tables-alt-pagination" */ '../views/example/tables/alt_pagination.vue'),
  },
  {
    path: '/example/tables/custom',
    name: 'example-custom',
    component: () => import(/* webpackChunkName: "tables-custom" */ '../views/example/tables/custom.vue'),
  },
  {
    path: '/example/tables/range-search',
    name: 'example-range-search',
    component: () => import(/* webpackChunkName: "tables-range-search" */ '../views/example/tables/range_search.vue'),
  },
  {
    path: '/example/tables/export',
    name: 'example-export',
    component: () => import(/* webpackChunkName: "tables-export" */ '../views/example/tables/export.vue'),
  },
  {
    path: '/example/tables/live-dom-ordering',
    name: 'example-live-dom-ordering',
    component: () => import(/* webpackChunkName: "tables-live-dom-ordering" */ '../views/example/tables/live_dom_ordering.vue'),
  },
  {
    path: '/example/tables/miscellaneous',
    name: 'example-miscellaneous',
    component: () => import(/* webpackChunkName: "tables-miscellaneous" */ '../views/example/tables/miscellaneous.vue'),
  },
  // END Example -----------------------
  // {
  //     path: '/',
  //     name: 'dashboard',
  //     component: () => import(/* webpackChunkName: "dashboard" */ '../views/index.vue'),
  // },
  {
    path: '/',
    name: 'challenge',
    component: () => import(/* webpackChunkName: "challenge-register" */ '../views/challenge_register.vue'), 
  },
  {
    path: '/challenge-register',
    name: 'challenge-register',
    component: () => import(/* webpackChunkName: "challenge-register" */ '../views/challenge_register.vue'), 
  },
  {
    path: '/membermanagement',
    name: 'membermanagement',
    component: () => import(/* webpackChunkName: "membermanagement" */ '../views/membermanagement.vue'), 
  },
  {
    path: '/accountmatrix',
    name: 'accountmatrix',
    component: () => import(/* webpackChunkName: "accountmatrix" */ '../views/accountmatrix.vue'), 
  },
  {
    path: '/adminmanagement',
    name: 'adminmanagement',
    component: () => import(/* webpackChunkName: "adminmanagement" */ '../views/adminmanagement.vue'), 
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/login.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/emailverification/:token',
    name: 'emailverification',
    props: true,
    component: () => import(/* webpackChunkName: "auth-login" */ '../views/auth/email_verification.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/pass-recovery',
    name: 'pass-recovery',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/pass_recovery.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/forgotpassword/:token',
    name: 'forgotpassword',
    props: true,
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/auth/forgotPassword.vue'),
    meta: { layout: 'auth' },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/users/profile.vue')
  },
  {
    path: '/account-setting',
    name: 'account-setting',
    component: () => import(/* webpackChunkName: "auth-pass-recovery" */ '../views/users/account_setting.vue')
  },
  {
    path: '/rulemanagement',
    name: 'rulemanagement',
    component: () => import(/* webpackChunkName: "rulemanagement" */ '../views/rulemanagement.vue')
  },
  {
    path: '/termandconditionmanagement',
    name: 'termandconditionmanagement',
    component: () => import(/* webpackChunkName: "termandconditionmanagement" */ '../views/tcmanagement.vue')
  },
  {
    path: '/cookiemanagement',
    name: 'cookiemanagement',
    component: () => import(/* webpackChunkName: "cookiemanagement" */ '../views/cookiemanagement.vue')
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import(/* webpackChunkName: "challenge-register" */ '../views/withdraw.vue')
  },
  {
    path: '/symbolmanagement',
    name: 'symbolmanagement',
    component: () => import(/* webpackChunkName: "symbolmanagement" */ '../views/symbolmanagement.vue')
  },
  {
    path: '/notifydemo',
    name: 'notifydemo',
    component: () => import(/* webpackChunkName: "notifydemo" */ '../views/notifydemo.vue')
  },
  {
    path: '/notifyreal',
    name: 'notifyreal',
    component: () => import(/* webpackChunkName: "notifyreal" */ '../views/notifyreal.vue')
  },
  {
    path: '/riskport',
    name: 'riskport',
    component: () => import(/* webpackChunkName: "riskport" */ '../views/riskport.vue')
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: () => import(/* webpackChunkName: "changePassword" */ '../views/auth/changePassword.vue')
  }
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'register', 'pass-recovery','emailverification', 'forgotpassword'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('member');
  const memberData = JSON.parse(loggedIn);
  const token = localStorage.getItem('token');
  // const { authorize } = to.meta;

  if (authRequired && !loggedIn && !token) {
    return next('/login');
  } else {
    if (memberData && memberData.role != 'admin') {
      return next('/login');
    }
  }

  if (to.path === '/') {
    return next('/challenge-register');
  }

  if (token && to.name == 'login' ) {
    return next('/');
}

if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
    store.commit('setLayout', 'auth');
} else {
    store.commit('setLayout', 'app');
}

  next();
});

export default router;
